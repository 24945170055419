import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { getParamsFromQuery } from 'services/queryString'
import css from './Brand.module.scss'

let handleDataLayerFunction = null

const Brand = ({ locale, pageType = 'Header' }) => {
  const { lang } = useTranslation('translations')
  const router = useRouter()
  const { query } = router
  const routerParams = getParamsFromQuery({ ...query })

  const handleDataLayer = async () => {
    // for back button handling in matchmaker form
    if(sessionStorage.getItem('PageRefresh')) {
      sessionStorage.removeItem('PageRefresh')
    }
    if (sessionStorage.getItem('abVarient')) {
      sessionStorage.removeItem('abVarient')
    }
    if (handleDataLayerFunction === null) {
      const { handleDataLayerMethodForBrand } = await import(
        /* webpackChunkName: "handleDataLayer" */ './Methods/onClickMethods'
      )
      handleDataLayerFunction = handleDataLayerMethodForBrand
    }
    handleDataLayerFunction(routerParams, lang, pageType)
  }
  return (
    <a href={lang==='en'? '/en': `/`} passHref locale={locale} prefetch={false} className={css.brand}>
      <div className={css.brand_div} onClick={handleDataLayer}>
        <Image
          width={180}
          height={62}
          id = "wasaltLogo"
          src={
            locale === 'ar'
              ? `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo-ar.svg?v1`
              : `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo.svg?v1`
          }
          alt={locale === 'ar' ? 'شعار وصلت' : 'Wasalt Logo'}
          className={css.logo}
        />
      </div>
    </a>
  )
}

export default Brand
